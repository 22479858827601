<template>
    <div class="search_filter_wrap">
        <div class="search_item_wrap">
            <div class="filter_category_box">
                <el-select id="category" v-model="searchInputOption" class="my_filter select w150"
                           popper-class="category_select"
                           :popper-append-to-body="false"
                >
                    <el-option :value="'ina'" :label="$t('subject')"></el-option>
                    <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
                    <el-option :value="'orni'" :label="$t('k_creator')"></el-option>
                    <el-option :value="'cuni'" :label="$t('curator_in_charge')"></el-option>
                </el-select>
            </div>
            <div class="search_input">
                <el-input :placeholder="$t('st')" v-model="searchInput"
                          @input="setData('searchInput',searchInput)"
                          @keypress.enter.native="setFilterFunc(searchInputOption,searchInput)"/>
                <button class="search_btn" @click="setFilterFunc(searchInputOption,searchInput)">
                    <span>{{ $t('search') }}</span>
                    <img src="@/assets/image/main/search_btn.png"></button>
            </div>
            <div class="end_filter">
                <!--        <button class="filter_btn two" @click="">-->
                <el-popover
                    v-model="showPopover"
                    popper-class="top_500"
                    placement=""
                    width="435"
                    trigger="click"
                    :open-delay="300"
                    :offset="0"
                    :append-to-body="false"
                    :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
                    <el-button slot="reference" class="filter_btn two" @click=""></el-button>
                    <div class="popup_tail"></div>
                    <div class="popup_bg">
                        <div class="popup_inner">
                            <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
                            <div class="inner_middle">
                                <div class="sub_title">{{$t('category')}}</div>
                                <div class="edition_wrap">
                                    <el-checkbox-group v-model="filterCategory"
                                                       @change="setFilterFunc('ic', filterCategory)">
                                        <el-checkbox :label="2">{{ $t('art') }}</el-checkbox>
                                        <el-checkbox :label="178">{{ $t('prompt') }}</el-checkbox>
                                        <el-checkbox :label="9">{{ $t('collections') }}</el-checkbox>
                                        <el-checkbox :label="3">{{ $t('picture') }}</el-checkbox>
                                        <el-checkbox :label="5">{{ $t('music') }}</el-checkbox>
<!--                                        <el-checkbox :label="4">{{ $t('video') }}</el-checkbox>-->
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{$t('edition')}}</div>
                                <div class="edition_wrap">
                                    <el-checkbox-group v-model="filterEdition"
                                                       @change="setFilterFunc('ed', filterEdition)">
                                        <el-checkbox :label="1">{{ $t('edition') }}</el-checkbox>
                                        <el-checkbox :label="0"> {{ $t('single') }}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{$t('type')}}</div>
                                <div class="type_wrap">
                                    <el-checkbox-group v-model="filterType"
                                                       @change="setFilterFunc('ty', filterType)">
                                        <el-checkbox :label="'digital'">{{ $t('digital') }}</el-checkbox>
                                        <el-checkbox :label="'real'">{{ $t('real') }}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
<!--                                <div class="sub_title">{{$t('item_status')}}</div>
                                <div class="type_wrap">
                                    <el-checkbox-group v-model="filterItemStatus" @change="setFilterFunc('st', filterItemStatus)">>
                                        <el-checkbox :label="'nosale'">
                                            {{$t('sales_not_set')}}
                                        </el-checkbox>
                                        <el-checkbox :label="'auction'">
                                            {{$t('auction_in_process')}}
                                        </el-checkbox>
                                        <el-checkbox :label="'pricetag'">
                                            {{$t('sale_limit')}}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>-->
                                <div class="sub_title">{{$t('curation_status')}}</div>
                                <div class="type_wrap">
                                    <el-checkbox-group v-model="filterCurationStatus" @change="setFilterFunc('cust', filterCurationStatus)">>
                                        <el-checkbox :label="0">{{$t('waiting_for_acceptance')}}</el-checkbox>
                                        <el-checkbox :label="1">{{$t('in_process2')}}</el-checkbox>
                                        <el-checkbox :label="4">{{$t('sell_completed')}}</el-checkbox>
                                        <el-checkbox :label="'cancel'">{{$t('cancel')}}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{$t('enroll')}}{{$t('dateTime')}}</div>
                                <div class="price_wrap">
                                    <el-date-picker
                                        class="range_time"
                                        v-model="filterDate"
                                        type="daterange"
                                        @change="setFilterFunc('cr', filterDate)"
                                        range-separator="~"
                                        value-format="yyyy-MM-dd"
                                        start-placeholder="YYYY-MM-DD"
                                        end-placeholder="YYYY-MM-DD ">
                                    </el-date-picker>
                                </div>
                                <div class="sub_title">{{$t('selling_price')}}({{$t('max_bid')}})</div>
                                <div class="price_wrap">
                                    <el-input v-model="priceInput[0]" class="el_price_input el_input_num"
                                              type="number" @keypress.native.enter="runPriceFilter()"/>
                                    <span> Mg</span>
                                    <span class="first_input_step">~</span>
                                    <el-input v-model="priceInput[1]" class="el_price_input el_input_num"
                                              type="number" @keypress.native.enter="runPriceFilter()"/>
                                    <span> Mg </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrapper_bottom" @click="runPriceFilter()">
                        <button class="">{{$t('apply2')}}</button>
                    </div>
                </el-popover>
            </div>
            <!--      <div class="filter_sort_box">
                    <el-select id="sort_select" v-model="sortOption" class="sort_filter select"
                               @change="sortChange(sortOption)">
                      <el-option :value="'i.updated_at'" :label="$t('b_update')"></el-option>
                      <el-option :value="'im_hit'" :label="$t('b_enter')"></el-option>
                      <el-option :value="'im_like'" :label="$t('b_like')"></el-option>
                      <el-option :value="'priceDesc'" :label="$t('priceDesc')"></el-option>
                      <el-option :value="'priceAsc'" :label="$t('priceAsc')"></el-option>
                    </el-select>
                  </div>-->


        </div>
    </div>
</template>

<script>
import {curationStatus} from "@/service/curationService";
import curationUtil from "@/components/curation/curationUtil";

const {filterConfig} = curationUtil;
export default {
    name: "CurationFilterDefaultLayout",
    mixins: [],
    components: {

    },
    inject: ['setFilter'],
    provide() {
        return{

        }
    },
    props: {
        filterData: {default: () => {}},
    },
    data() {
        return{
            sortOption: 'i.updated_at',
            searchInput: '',
            searchInputOption: 'ina',
            filterCategory: [],
            filterStatus: [],
            filterEdition: [],
            filterType: [],
            filterItemStatus: [],
            filterCurationStatus: [],
            priceInput: ["", ""],
            filterRequestDate: '',
            filterDate: '',

            curationStatus: curationStatus,
            showPopover: false,
        }
    },
    beforeRouterEnter() {},
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        runPriceFilter() {
            this.setFilterFunc('pr', this.priceInput);
            // this.showPopover = false;
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
